import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { filter, map } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import countryCodeData from '../../assets/countryCode.json';
import { LoginLogo } from '../../assets/svg/login';
import {
  COUNTRY_CODES,
  GA_EVENT,
  GA_LABEL,
  LOGIN_TYPE,
  REGEX,
  ROUTES,
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import useRouter from '../../common/useRouter';
import { formValidatorRules } from '../../common/utils';
import auth from '../../firebase';
import { GENERATE_USER_OTP } from './graphql/Mutations';
import { GET_LOGIN_TYPE } from './graphql/Queries';

const { required, numberAndEmail } = formValidatorRules;
const { Option } = Select;
const Login = () => {
  const [form] = Form.useForm();
  const { navigate, location } = useRouter();
  const [isMobileNumber, setIsMobileNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const pathname = location?.pathname;
  const loginInputRef = useRef();

  useEffect(() => {
    try {
      // eslint-disable-next-line no-undef
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        'recaptcha-container',
        {
          size: 'invisible',
        },
      );
    } catch (error) {
      return error;
    }
  }, []);

  const [generateUserOtp] = useMutation(GENERATE_USER_OTP, {
    onError() {},
    onCompleted() {
      const { identifier, countryCode } = form.getFieldsValue();
      Event(GA_EVENT.REQUESTED_OTP, {
        label: GA_LABEL.REQUESTED_OTP,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        identifier,
        countryCode,
      });
    },
  });

  const [getLoginType, { loading: loginTypeLoading }] = useLazyQuery(
    GET_LOGIN_TYPE,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted(res) {
        const { identifier, countryCode } = form.getFieldsValue();
        const countryShortForm = filter(
          countryCodeData,
          (record) => record?.dialCode === countryCode,
        )?.[0]?.countryCode?.toUpperCase();
        if (
          res?.getLoginType?.loginType === LOGIN_TYPE.OTP &&
          countryCode === COUNTRY_CODES.IN
        ) {
          generateUserOtp({
            variables: {
              data: {
                phoneNo: `${countryCode}${identifier}`,
                country: countryShortForm,
              },
            },
          });
          navigate(ROUTES.VERIFY, {
            state: {
              phone: identifier,
              countryCode,
              pathname,
              loginType: res?.getLoginType?.loginType,
            },
          });
        } else if (
          res?.getLoginType?.loginType === LOGIN_TYPE.OTP &&
          countryCode === COUNTRY_CODES.AE
        ) {
          signInWithPhoneNumber(
            auth,
            `${countryCode}${identifier}`,
            // eslint-disable-next-line no-undef
            window.recaptchaVerifier,
          )
            ?.then((confirmationResult) => {
              // eslint-disable-next-line no-undef
              window.confirmationResult = confirmationResult;
              message.success('Otp sent successfully!');
              setIsLoading(false);
              Event(GA_EVENT.REQUESTED_OTP_WITH_FIREBASE, {
                label: GA_LABEL.REQUESTED_OTP_WITH_FIREBASE,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href,
                phone: identifier,
                countryCode,
              });
              navigate(ROUTES.VERIFY, {
                state: {
                  phone: identifier,
                  countryCode,
                  pathname,
                  loginType: res?.getLoginType?.loginType,
                },
              });
            })
            ?.catch(() => {
              setIsLoading(false);
              message.destroy();
              message.error('Something went wrong!');
            });
        } else if (res?.getLoginType?.loginType === LOGIN_TYPE.OTP) {
          // eslint-disable-next-line no-undef
          window.sendOtp(
            `${countryCode?.replace('+', '')}${identifier}`,
            () => {
              message.success('Otp sent successfully!');
              setIsLoading(false);
              Event(GA_EVENT.REQUESTED_OTP_WITH_WHATSAPP, {
                label: GA_LABEL.REQUESTED_OTP_WITH_WHATSAPP,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href,
                phone: identifier,
                countryCode,
              });
              navigate(ROUTES.VERIFY, {
                state: {
                  phone: identifier,
                  countryCode,
                  pathname,
                  loginType: res?.getLoginType?.loginType,
                },
              });
            },
            () => {
              setIsLoading(false);
              message.destroy();
              message.error('Something went wrong!');
            },
          );
        } else {
          navigate(ROUTES.VERIFY, {
            state: {
              phone: identifier,
              countryCode,
              loginType: res?.getLoginType?.loginType,
              pathname,
            },
          });
        }
      },
    },
  );

  const handleInputChange = (e) => {
    const { value } = e?.target;
    if (value?.length === 0) {
      setIsMobileNumber(false);
    }
    if (REGEX?.PHONE?.test(value)) {
      setIsMobileNumber(true);
      loginInputRef?.current?.focus();
    }
    if (REGEX?.EMAIL?.test(value)) {
      setIsMobileNumber(false);
      loginInputRef?.current?.focus();
    }
  };

  useEffect(() => {
    loginInputRef?.current?.focus();
  }, [isMobileNumber]);

  const onFinish = async ({ identifier, countryCode }) => {
    if (isMobileNumber) {
      const countryShortForm = filter(
        countryCodeData,
        (record) => record?.dialCode === countryCode,
      )?.[0]?.countryCode?.toUpperCase();
      getLoginType({
        variables: {
          data: {
            phoneNo: `${countryCode}${identifier}`,
            country: countryShortForm,
          },
        },
      });
    } else {
      navigate(ROUTES.VERIFY, {
        state: {
          email: identifier,
          countryCode,
          pathname,
        },
      });
    }
  };

  return (
    <div className="gx-login-container">
      <div id="recaptcha-container" className="d-none" />
      <div className="login-logo">
        <LoginLogo width="200" height="60" />
      </div>
      <div className="gx-login-content">
        <div className="card-body">
          <h1>Login</h1>
          <p>Sign in to your account</p>
          <Form
            layout="vertical"
            name="Login"
            className="gx-login-form gx-form-row0 login-form"
            form={form}
            initialValues={{ countryCode: COUNTRY_CODES.IN }}
            onFinish={onFinish}
          >
            <Form.Item
              name="identifier"
              rules={[required, numberAndEmail]}
              label="Email or mobile phone number"
              className="phone-input"
            >
              <Input
                placeholder="Enter valid email or mobile number"
                ref={loginInputRef}
                onChange={handleInputChange}
                addonBefore={
                  isMobileNumber ? (
                    <Form.Item
                      name="countryCode"
                      className="country-code-input"
                    >
                      <Select showSearch>
                        {map(countryCodeData, (record) => (
                          <Option key={record?.id} value={record?.dialCode}>
                            {record?.dialCode}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null
                }
              />
            </Form.Item>
            <Row className="login-row">
              <Col>
                <Form.Item className="login-button">
                  <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    loading={loginTypeLoading || isLoading}
                  >
                    Next
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
