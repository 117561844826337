import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import clsx from 'clsx';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { filter, get, includes, map } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import OTPInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import countryCodeData from '../../assets/countryCode.json';
import { LoginLogo } from '../../assets/svg/login';
import api from '../../common/api';
import {
  BREAKPOINTS,
  COUNTRY_CODES,
  COUNTRY_SORT_NAME,
  EQC_FILTER,
  EQC_STAGE_STATUS_KEYS,
  GA_EVENT,
  GA_LABEL,
  INS_STATUS_KEYS,
  ISSUE_FILTER,
  LOGIN_TYPE,
  ROUTES,
  TENANT_ID,
  TIMEOUT_FOR_OTP_IN_SECONDS,
  TIMEOUT_FOR_OTP_IN_SECONDS_FOR_WHATSAPP,
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import useRouter from '../../common/useRouter';
import { formValidatorRules } from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import auth from '../../firebase';
import {
  GENERATE_USER_OTP,
  LOGIN,
  LOGIN_USER_WITH_FIREBASE,
  LOGIN_USER_WITH_OTP,
  LOGIN_USER_WITH_WHATSAPP,
} from './graphql/Mutations';

const { required, number } = formValidatorRules;
const Verify = () => {
  const [isResent, setIsResent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showTenantSelectionModal, setShowTenantSelectionModal] =
    useState(false);
  const [tenants, setTenants] = useState([]);
  const [selectedTenantId, setSelectedTenantId] = useState();
  const [resendLoading, setResendLoading] = useState(false);
  const [getOtpClicked, setGetOtpClicked] = useState(false);
  const { initializeAuth } = useContext(AppContext);
  const { navigate, location } = useRouter();
  const [form] = Form.useForm();
  const isMobileViewport = useMedia(`(max-width: ${BREAKPOINTS.mobile}px)`);
  const phone = location?.state?.phone;
  const countryCode = location?.state?.countryCode;
  const pathname = location?.state?.pathname;
  const loginType = location?.state?.loginType;
  const userEmail = location?.state?.email;
  const passwordInputRef = useRef();
  if ((!phone || !countryCode) && !userEmail) {
    navigate(ROUTES.LOGIN);
  }

  const successCallback = (accessToken, refreshToken) => {
    initializeAuth(accessToken, refreshToken);
    if (loginType === LOGIN_TYPE.OTP) {
      // eslint-disable-next-line no-undef
      localStorage.setItem(
        ISSUE_FILTER,
        JSON.stringify([INS_STATUS_KEYS?.RAISED, INS_STATUS_KEYS?.REJECTED]),
      );
      // eslint-disable-next-line no-undef
      localStorage.setItem(EQC_FILTER, EQC_STAGE_STATUS_KEYS?.APPROVAL_PENDING);
    }
    if (pathname && ![ROUTES.LOGOUT, ROUTES.VERIFY]?.includes(pathname)) {
      navigate(pathname);
    } else {
      navigate(ROUTES.MAIN);
    }
  };

  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {
      // eslint-disable-next-line no-undef
      localStorage.removeItem(TENANT_ID);
    },
  });

  const [loginUserWithOtp, { loading }] = useMutation(LOGIN_USER_WITH_OTP, {
    onError() {},
    onCompleted(res) {
      const tenantData = get(res, 'data.loginUserWithOTP.tenantUserData');
      if (tenantData) {
        if (tenantData?.length > 1) {
          setTenants(tenantData);
          setShowTenantSelectionModal(true);
        }
      } else {
        successCallback(
          res?.loginUserWithOTP?.token,
          res?.loginUserWithOTP?.refreshToken,
        );
      }
      Event(GA_EVENT.REQUESTED_OTP, {
        label: GA_LABEL.REQUESTED_OTP,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        phone,
        countryCode,
        email: userEmail,
      });
    },
  });

  const [loginUserWithFirebase] = useMutation(LOGIN_USER_WITH_FIREBASE, {
    onError() {
      setResendLoading(false);
    },
    onCompleted(res) {
      successCallback(res?.loginUserWithFirebase?.token);
      setIsLoading(false);
      Event(GA_EVENT.REQUESTED_OTP_WITH_FIREBASE, {
        label: GA_LABEL.REQUESTED_OTP_WITH_FIREBASE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        phone,
        countryCode,
        email: userEmail,
      });
    },
  });

  const [loginUserWithWhatsApp] = useMutation(LOGIN_USER_WITH_WHATSAPP, {
    onError() {
      setIsLoading(false);
    },
    onCompleted(res) {
      successCallback(res?.loginUserWithWhatsApp?.token);
      setIsLoading(false);
      Event(GA_EVENT.REQUESTED_OTP_WITH_WHATSAPP, {
        label: GA_LABEL.REQUESTED_OTP_WITH_WHATSAPP,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        phone,
        countryCode,
        email: userEmail,
      });
    },
  });

  const [generateUserOtp] = useMutation(GENERATE_USER_OTP, {
    onError() {},
    onCompleted() {
      setResendLoading(false);
      Event(GA_EVENT.RESEND_OTP, {
        label: GA_LABEL.RESEND_OTP,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        phone,
        countryCode,
        email: userEmail,
      });
    },
  });

  useEffect(() => {
    passwordInputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (!includes([COUNTRY_CODES.IN, COUNTRY_CODES.AE], countryCode)) {
      setTimeLeft(TIMEOUT_FOR_OTP_IN_SECONDS_FOR_WHATSAPP);
      setIsResent(true);
    }
  }, [countryCode]);

  useEffect(() => {
    let intervalRef;
    if (isResent) {
      intervalRef = setInterval(() => {
        setTimeLeft((currentTime) => {
          if (currentTime - 1 === 0) {
            setIsResent(false);
          }
          return currentTime - 1;
        });
      }, 1000);
    } else {
      clearInterval(intervalRef);
    }
    return () => {
      clearInterval(intervalRef);
    };
  }, [isResent]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-undef
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        'recaptcha-container',
        { size: 'invisible' },
      );
    } catch (error) {
      return error;
    }
  }, []);

  const loginWithSelectedTenant = async (tenantData, tenantId) => {
    const value = form.getFieldsValue(true);
    const { password } = value;
    // eslint-disable-next-line no-undef
    localStorage.setItem(TENANT_ID, tenantId);
    if (!loginLoading) {
      if (password) {
        const countryShortForm = filter(
          countryCodeData,
          (record) => record?.dialCode === countryCode,
        )?.[0]?.countryCode?.toUpperCase();
        const selectedTenantResponse = await loginMutate({
          variables: {
            data: {
              email: userEmail,
              phoneNo: phone,
              country: countryShortForm,
              password,
              webAccess: true,
            },
          },
        });
        const newAccessToken = get(selectedTenantResponse, 'data.login.token');
        const newRefreshToken = get(
          selectedTenantResponse,
          'data.login.refreshToken',
        );
        successCallback(newAccessToken, newRefreshToken);
      }
      //! for future use
      // else {
      //   let selectedTenantResponse = {};
      //   if (COUNTRY_CODES.IN === countryCode) {
      //     selectedTenantResponse = await loginUserWithOtp({
      //       variables: {
      //         data: {
      //           phoneNo: phone,
      //           country: COUNTRY_SORT_NAME.IN,
      //           otp: parseInt(otp, 10),
      //         },
      //       },
      //     });
      //   }
      //   const newAccessToken = get(
      //     selectedTenantResponse,
      //     'data.loginUserWithOTP.token',
      //   );
      //   const newRefreshToken = get(
      //     selectedTenantResponse,
      //     'data.loginUserWithOTP.refreshToken',
      //   );
      //   successCallback(newAccessToken, newRefreshToken);
      // }
    }
  };

  const onFinish = async ({ otp, password }) => {
    if (
      loginType === LOGIN_TYPE.OTP ||
      (loginType === LOGIN_TYPE.PASSWORD && phone && getOtpClicked)
    ) {
      if (COUNTRY_CODES.IN === countryCode) {
        loginUserWithOtp({
          variables: {
            data: {
              phoneNo: `${countryCode}${phone}`,
              country: COUNTRY_SORT_NAME.IN,
              otp: parseInt(otp, 10),
            },
          },
        });
      } else {
        setIsLoading(true);
        const countryShortForm = filter(
          countryCodeData,
          (record) => record?.dialCode === countryCode,
        )?.[0]?.countryCode?.toUpperCase();
        if (countryCode === COUNTRY_CODES.AE) {
          // eslint-disable-next-line no-undef
          window?.confirmationResult
            ?.confirm(otp)
            ?.then(async () => {
              auth?.currentUser?.getIdToken()?.then(async (res) => {
                await loginUserWithFirebase({
                  variables: {
                    data: {
                      firebaseToken: res,
                      country: countryShortForm,
                    },
                  },
                });
              });
            })
            ?.catch((error) => {
              setIsLoading(false);
              message.destroy();
              message.error(
                error?.code === 'auth/code-expired'
                  ? 'OTP Expired'
                  : 'Invalid OTP',
              );
            });
        } else {
          // eslint-disable-next-line no-undef
          window?.verifyOtp(
            parseInt(otp, 10),
            (data) => {
              loginUserWithWhatsApp({
                variables: {
                  data: {
                    accessToken: data?.message,
                    country: countryShortForm,
                  },
                },
              });
            },
            (error) => {
              setIsLoading(false);
              message.destroy();
              message.error(error?.message);
            },
          );
        }
      }
    }
    if ((userEmail || loginType === LOGIN_TYPE.PASSWORD) && !getOtpClicked) {
      try {
        const countryShortForm = filter(
          countryCodeData,
          (record) => record?.dialCode === countryCode,
        )?.[0]?.countryCode?.toUpperCase();
        const response = await loginMutate({
          variables: {
            data: {
              email: userEmail,
              phoneNo: `${countryCode}${phone}`,
              password,
              country: countryShortForm,
              webAccess: true,
            },
          },
        });

        const errorResponse = response?.errors?.message;
        const accessToken = get(response, 'data.login.token');
        const refreshToken = get(response, 'data.login.refreshToken');
        const tenantData = get(response, 'data.login.tenantUserData');

        if (tenantData) {
          if (tenantData?.length > 1) {
            setTenants(tenantData);
            setShowTenantSelectionModal(true);
          } else {
            loginWithSelectedTenant(tenantData, tenantData[0]?.tenantId);
          }
        } else if (!errorResponse) {
          delete api?.defaults?.headers?.common?.tenantid;
          successCallback(accessToken, refreshToken);
        } else {
          form.resetFields(['password']);
        }
      } catch (error) {
        return error;
      }
    }
  };

  const handleResend = () => {
    setResendLoading(true);
    setIsResent(true);
    if (COUNTRY_CODES.IN === countryCode) {
      setTimeLeft(TIMEOUT_FOR_OTP_IN_SECONDS);
      generateUserOtp({
        variables: {
          data: {
            phoneNo: `${COUNTRY_CODES.IN}${phone}`,
            country: COUNTRY_CODES.IN,
          },
        },
      });
    } else if (COUNTRY_CODES.AE === countryCode) {
      setTimeLeft(TIMEOUT_FOR_OTP_IN_SECONDS);
      signInWithPhoneNumber(
        auth,
        `${countryCode}${phone}`,
        // eslint-disable-next-line no-undef
        window?.recaptchaVerifier,
      )
        ?.then((confirmationResult) => {
          Event(GA_EVENT.RESEND_OTP_WITH_FIREBASE, {
            label: GA_LABEL.RESEND_OTP_WITH_FIREBASE,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            phone,
            countryCode,
            email: userEmail,
          });
          setResendLoading(false);
          setIsResent(true);
          // eslint-disable-next-line no-undef
          window.confirmationResult = confirmationResult;
          message.success('Otp sent successfully!');
        })
        ?.catch(() => {
          setResendLoading(false);
          message.destroy();
          message.error('Something went wrong!');
        });
    } else {
      setTimeLeft(TIMEOUT_FOR_OTP_IN_SECONDS_FOR_WHATSAPP);
      // eslint-disable-next-line no-undef
      window?.retryOtp(
        '12',
        () => {
          Event(GA_EVENT.RESEND_OTP_WITH_WHATSAPP, {
            label: GA_LABEL.RESEND_OTP_WITH_WHATSAPP,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            phone,
            countryCode,
            email: userEmail,
          });
          setResendLoading(false);
          setIsResent(true);
        },
        (error) => {
          setResendLoading(false);
          message.destroy();
          message.error(error?.message);
        },
      );
    }
  };

  const handleGetOtpClick = () => {
    setGetOtpClicked(true);
    const countryShortForm = filter(
      countryCodeData,
      (record) => record?.dialCode === countryCode,
    )?.[0]?.countryCode?.toUpperCase();
    if (countryCode === COUNTRY_CODES.IN) {
      generateUserOtp({
        variables: {
          data: {
            phoneNo: `${countryCode}${phone}`,
            country: countryShortForm,
          },
        },
      });
    } else if (countryCode === COUNTRY_CODES.AE) {
      const phoneNo = `${countryCode}${phone}`;
      // eslint-disable-next-line no-undef
      signInWithPhoneNumber(auth, phoneNo, window.recaptchaVerifier)
        ?.then((result) => {
          // eslint-disable-next-line no-undef
          window.confirmationResult = result;
          message.success('Otp sent successfully!');
          Event(GA_EVENT.REQUESTED_OTP_WITH_FIREBASE, {
            label: GA_LABEL.REQUESTED_OTP_WITH_FIREBASE,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            phone: phoneNo,
            countryCode,
          });
        })
        ?.catch(() => {
          message.destroy();
          message.error('Something went wrong!');
        });
    } else {
      // eslint-disable-next-line no-undef
      window?.sendOtp(`${countryCode?.replace('+', '')}${phone}`, () => {
        message.success('Otp sent successfully!');
      });
      Event(GA_LABEL.REQUESTED_OTP_WITH_WHATSAPP, {
        label: GA_LABEL.REQUESTED_OTP_WITH_WHATSAPP,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        phone,
        countryCode,
      });
    }
    navigate(ROUTES.VERIFY, {
      state: {
        phone,
        countryCode,
        loginType,
      },
    });
  };

  const renderHeadingText = () => {
    if (
      loginType === LOGIN_TYPE.OTP ||
      (loginType === LOGIN_TYPE.PASSWORD && phone && getOtpClicked)
    ) {
      return (
        <>
          <h1>Verify your phone</h1>
          <p className="mt-0">
            Enter OTP sent to {countryCode}
            {phone}
          </p>
        </>
      );
    }
    if (userEmail || loginType === LOGIN_TYPE.PASSWORD) {
      return (
        <>
          <h1>Verify</h1>
          <p>Please verify the email</p>
        </>
      );
    }
  };

  const renderInputField = () => {
    if (
      loginType === LOGIN_TYPE.OTP ||
      (loginType === LOGIN_TYPE.PASSWORD && phone && getOtpClicked)
    ) {
      return (
        <>
          <Form.Item
            name="otp"
            rules={[required, number]}
            className="otp-input"
          >
            <OTPInput
              numInputs={6}
              onChange={() => {}}
              isInputNum
              className="otp-input"
              shouldAutoFocus
              renderInput={(props) => <input {...props} />}
            />
          </Form.Item>
        </>
      );
    }
    if (userEmail || loginType === LOGIN_TYPE.PASSWORD) {
      return (
        <>
          <Form.Item name="password" rules={[required]} label="Password">
            <Input.Password placeholder="Password" ref={passwordInputRef} />
          </Form.Item>
          <Row className="login-password-row">
            <Col>
              <Link to={ROUTES.FORGOT}>Forgot password ?</Link>
            </Col>
            {loginType === LOGIN_TYPE.PASSWORD && phone ? (
              <Col>
                <div
                  className="fw-semi-bold text-color pointer"
                  onClick={handleGetOtpClick}
                >
                  Get Otp
                </div>
              </Col>
            ) : null}
          </Row>
        </>
      );
    }
  };

  return (
    <div className="gx-login-container">
      <div id="recaptcha-container" className="d-none" />
      <div className="login-logo">
        <LoginLogo width="200" height="60" />
      </div>
      <div className="gx-login-content">
        <div className="card-body">
          {renderHeadingText()}
          <Form
            layout="vertical"
            name="Login"
            className="gx-login-form gx-form-row0"
            form={form}
            onFinish={onFinish}
          >
            {renderInputField()}
            <div
              className={`d-flex align-center mt-15 ${loginType === LOGIN_TYPE.OTP ? 'justify-start' : 'justify-end'}`}
            >
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                className="mb-25"
                loading={loading || isLoading}
              >
                Login
              </Button>
            </div>
          </Form>
          {loginType === LOGIN_TYPE.OTP ||
          (loginType === LOGIN_TYPE.PASSWORD && phone && getOtpClicked) ? (
            <div
              className={clsx('d-flex', isMobileViewport && 'flex-vertical')}
            >
              {isResent ? (
                <div className="text-secondary mr-5">
                  Send again in {timeLeft} seconds
                </div>
              ) : (
                <div className={clsx('text-secondary d-flex mr-5')}>
                  Didn't receive OTP ?
                  <div
                    className="text-primary pointer ml-5"
                    role="button"
                    onClick={resendLoading ? undefined : handleResend}
                    tabIndex={0}
                  >
                    {resendLoading ? 'Sending...' : 'Send Again'}
                  </div>
                  {!resendLoading && (
                    <div className="text-secondary ml-5">or</div>
                  )}
                </div>
              )}
              <div
                className="text-primary pointer"
                role="button"
                tabIndex={0}
                onClick={() => navigate(ROUTES.MAIN)}
              >
                Change Number
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {loginType === LOGIN_TYPE.PASSWORD || userEmail ? (
        <div id="tenant-list">
          <Modal
            open={showTenantSelectionModal}
            onOk={() => loginWithSelectedTenant(tenants, selectedTenantId)}
            onCancel={() => setShowTenantSelectionModal(false)}
            okButtonProps={{
              loading: loginLoading,
              shape: 'round',
              disabled: !selectedTenantId,
              className: !selectedTenantId && 'no-hover',
            }}
            okText="Proceed"
            cancelButtonProps={{ shape: 'round' }}
            centered
            closable={false}
            // eslint-disable-next-line no-undef
            getContainer={() => document.getElementById('tenant-list')}
          >
            <div className="mb-10">
              <span className="required-mark">*</span>Select organization to
              Proceed
            </div>
            <CommonSelect
              placeholder="Select organization to login with"
              onChange={setSelectedTenantId}
              className="width-percent-100"
              options={[
                ...map(tenants, (tenant) => {
                  return {
                    key: tenant?.tenantId,
                    value: tenant?.tenantId,
                    label: tenant?.tenant?.organizationName,
                  };
                }),
              ]}
            />
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default Verify;
